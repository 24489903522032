.sidebar {
    overflow: auto;
    /* width: 16.8%; */
    min-width: 242px;
    background-color: #215493;
    display: flex;
    flex-direction: column;
}

.sidebar .header-sidebar,
.sidebar .menu-list, 
.sidebar .dropdown-container {
    padding: 16px;
}

.sidebar p {
    margin: 0;
}

.header-sidebar {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
    /* font-weight: 700; */
    color: #FFFFFF;
    margin-left: 12px !important;
}

.menu-list {
    width: calx(100%- 32px);
    margin-top: 8px;
}

.menu-item {
    padding: 8px 14px;
    gap: 12px;
    /* width: 100%; */
    height: 40px;
    /* background: #7A98BE; */
    border-radius: 8px;
    align-items: center;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}

.dropdown-container {
    /* display: none; */
    /* background-color: #262626; */
    /* padding-left: 8px; */
    margin-top: -18px;
}

.dropdown-container p {
    display: flex;
    padding: 8px 0px 8px 18px;
    /* margin-left: 14px; */
    gap: 14px;
    /* height: 38px; */
    text-decoration: none;
    align-items: center;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}

.sub-menu .arrow-icon.open {
    transform: rotate(180deg);
}

.sub-menu .arrow-icon {
    transform: none;
    transition: all 0.4s ease 0s;
}

@media only screen and (max-width: 992px) {
    .sidebar {
        display: none !important;
    }
}